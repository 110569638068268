/* eslint-disable @next/next/no-img-element */
import Link from "next/link"
import React from "react"
import IconButton from "../../global/Buttons/IconButton"
import {useAuth, updateUserInfo, loadCurrentUser} from "../../../context/authContext"

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const {state: {user, loading}, dispatch } = useAuth()

  return (
    <section id="footer" className="container p-4 mt-20">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div>
          <h4 className="text-base font-bold uppercase pb-2">Content</h4>
          <ul className="font-extralight">
            <li>
              <Link href="/wines">Advanced Search</Link>
            </li>
            <li>
              <Link href="/articles">Articles</Link>
            </li>
            <li>
              <Link href="/multimedia">Videos</Link>
            </li>
            <li>
              <Link href="/articles/vinous-vineyard-maps">Maps</Link>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="text-base font-bold uppercase pb-2">Engage</h4>
          <ul className="font-extralight">
            {user ?
              <li>
                <Link href="/users/edit">Edit Account</Link>
              </li> : ""
            }
            <li>
              <Link href="/events">Events</Link>
            </li>
            <li>
              <a
                target="_blank"
                href="https://forum.vinous.com/"
                rel="noopener noreferrer"
              >
                Your Say
              </a>
            </li>
            <li>
              <Link href="/statics/consumer_resources">Consumer Resources</Link>
            </li>
            <li>
              <Link href="https://billing.vinous.com/subscriptions">Subscriptions & Gifts</Link>
            </li>
            <li>
              <Link href="/statics/samples">Sample Submissions</Link>
            </li>
            <li>
              <Link href="/statics/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="text-base font-bold uppercase pb-2">Vinous</h4>
          <ul className="font-extralight">
            <li>
              <Link href="/about">About</Link>
            </li>
            <li>
              <Link href="/statics/approach">Core Values</Link>
            </li>
            <li>
              <Link href="/statics/press">Press</Link>
            </li>
            <li>
              <Link href="/statics/reference_materials">
                Reference Materials
              </Link>
            </li>
            <li>
              <Link href="/statics/vinous-young-wine-writer-fellowship">
                Young Wine Writer Fellowship
              </Link>
            </li>
            <li>
              <Link href="/statics/products">Products</Link>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="text-base font-bold uppercase pb-2">Social</h4>
          <div className="flex space-x-2">
            <IconButton onClick={() =>
              window.open("https://twitter.com/VinousMedia", "_blank")
            }>
              <img src="/img/x-logo-black.png" alt="X logo" width={24} height={24}/>
            </IconButton>
            <IconButton onClick={() =>
              window.open("https://www.facebook.com/vinousmedia", "_blank")
            }>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
            </IconButton>
            <IconButton
              onClick={() =>
                window.open("https://www.instagram.com/vinousmedia", "_blank")
              }
            >
              <img src="/img/instagram-logo-black.png" alt="X logo" width={24} height={24}/>
            </IconButton>
            <IconButton
              onClick={(e) => {
                window.location.href = "mailto:info@vinousmedia.com";
                e.preventDefault();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            </IconButton>
          </div>
        </div>
      </div>

      <div className="container w-full flex justify-center pt-16 pb-4 px-4">
        <p className="text-sm">
          { `© ${currentYear} Vinous Media LLC` } · <Link href="/statics/privacy">Privacy</Link>{" "}
          · <Link href="/statics/terms_and_conditions">Terms & Conditions</Link>
        </p>
      </div>
    </section>
  )
}

export default Footer;
