import React from "react"
import { useAuth, loadCurrentUser } from "../../../context/authContext"
import BreakpointHelper from "../../global/_utility/BreakpointHelper"
import Footer from "../Footer/Footer";
import Head from "next/head";

const Layout = (props: {
  children?: any
  className?: string
  disablePadding?: boolean
  disableScroll?: boolean
}) => {
  const {
    state: { initialLoad },
    dispatch: userDispatch,
  } = useAuth()

  React.useEffect(() => {
    if (!initialLoad) {
      loadCurrentUser(userDispatch)
    }
  }, [initialLoad])

  const MemodFooter = React.useMemo(() => Footer, []);

  return (
    <>
      <Head>
        <title>{"Vinous | Explore All Things Wine"}</title>
        <meta content="IE=edge,chrome=1" httpEquiv="X-UA-Compatible" />
        <meta
          name="google-translate-customization"
          content="bc9c404857e6094b-bd2091ef6a1aacc6-g5523c9461670c8e8-21"
        />
        <meta name="viewport" content="width=device-width" />
        <meta
          name="description"
          content="At the intersection of media and technology, Vinous is Antonio Galloni's vision of a modern-day wine publication."
        />
      </Head>
      <div
        className={`container mx-auto pt-32 antialiased ${
          props.disableScroll && "fixed top-0"
        } ${props.disablePadding || "px-4"} ${props.className} `}
      >
          {props.children}
        <MemodFooter />
        <BreakpointHelper />
      </div>
    </>
  )
}

export default Layout
