import React from "react";
import Link from "next/link";
import Image from "next/image";

export default function Logo() {
  return (
    <Link href="/">
      <Image
        className="h-14 w-full object-contain"
        src="/img/vinous-logo.png"
        alt="Vinous Logo"
        width="0"
        height="0"
        sizes="100vw"
        priority
      />
    </Link>
  );
}
