import React, { Fragment } from "react";
import Link from "next/link";

const MenuItem = (props) => {
  const newTabProps = props.newTab
    ? {
        target: "_blank",
        rel: "noopener noreferrer",
      }
    : {};

  return (
    <li className={`dropdown transition relative ${props.styleName}`}>
      <Link
        href={props.path}
        {...newTabProps}
      >
        <div className="flex items-center duration-100 opacity-80 hover:opacity-100 hover:text-red cursor-pointer">
          {props.label}
          {props.children && (
            <div className="pl-1 pb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
        </div>
      </Link>
      {props.children && (
        <div
          className={`${
            props.hideChildren ? "hidden absolute" : "block relative"
          } transition-all space-y-2 lg:space-y-1 bg-white rounded-md p-3 pb-2 pl-6 lg:pl-3 text-base dropdown-menu w-48 lg:shadow-lg lg:hidden lg:absolute -left-3`}
        >
          {props.children}
        </div>
      )}
    </li>
  );
};

export default MenuItem;
