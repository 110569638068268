import React from "react";
import * as theme from "tailwindcss/defaultTheme";

// If custom breakpoints are implemented, use those instead
// import tailwindConfig from "../../../tailwind.config";

const BreakpointHelper = () => {
  const { REACT_APP_UI_HELPERS } = process.env;
  const breakpoints = Object.keys(theme.screens);

  return (
    <>
      {
        REACT_APP_UI_HELPERS &&
        <div className="fixed bottom-0 left-0 bg-gray-300 shadow-lg p-1 text-xs rounded-tr">
          {
            breakpoints &&
            breakpoints.map((bp, i) => {
              return (
                <div key={"bp-" + i} className={`${ i != 0 ? "hidden" : "" } ${ bp + ":block" } ${ breakpoints[i+1] + ":hidden" }`}>{bp.toUpperCase()}</div>
              )
            })
          }
        </div>
      }
    </>
  )
}

export default BreakpointHelper;