import React from "react";

interface IconButtonProps {
  children: any, 
  onClick: any,
  className?: string
};

const IconButton = ({
  children,
  onClick,
  className 
}: IconButtonProps) => {
	return (
		<div className={`transition-all bg-red bg-opacity-10 hover:bg-opacity-100 hover:text-white rounded-md h-12 w-12 flex justify-center items-center cursor-pointer ${ className }`} onClick={ onClick }>
			{ children }
		</div>
	)
}

export default IconButton;
